<template>
  <div>
    <v-hover v-slot:default="{ hover }">
      <v-list-item
        :class="{ 'grey lighten-4': hover }"
        @click="expanded = !expanded"
      >
        <v-list-item-action class="py-0 my-0" v-if="!displayOnly">
          <v-checkbox
            color="accent"
            @change="resourceUpdated(node, $event)"
            :indeterminate="
              checkSomeChildNodePermissions(node) && !checkboxState
            "
            v-model="checkboxState"
            class="pa-0 ma-0"
            hide-details
          ></v-checkbox>
        </v-list-item-action>
        <v-list-item-content class="pa-0 ma-0">
          <v-list-item-title class>
            <span class="font-weight-regular">{{
              node.display_name ? node.display_name : node.name
            }}</span>
          </v-list-item-title>
          <v-list-item-subtitle>
            <span class="font-weight-thin">{{ node.description }}</span>
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action class="py-0 my-0">
          <v-btn
            v-if="!expanded"
            @click.self="expanded = true"
            fab
            class="ma-0 pa-0"
            x-small
            text
            color="grey"
          >
            <v-icon small>fas fa-chevron-down</v-icon>
          </v-btn>
          <v-btn
            v-else
            @click.self="expanded = false"
            fab
            class="ma-0 pa-0"
            x-small
            text
            color="grey"
          >
            <v-icon small>fas fa-chevron-up</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-hover>
    <div v-if="expanded">
      <div v-if="node.permissions.length > 0" class="ml-5">
        <v-layout row wrap align-baseline mb-2 pl-2>
          <v-icon small class="mr-3 darkGrey--text text--lighten-3"
            >fas fa-lock</v-icon
          >
          <p class="subtitle-1 ma-0 mt-2 font-weight-medium primary--text">
            Permissions
          </p>
        </v-layout>
        <span v-for="perm in node.permissions" :key="perm.id">
          <v-list-item>
            <v-list-item-action class="py-0 my-0" v-if="!displayOnly">
              <v-checkbox
                dense
                @change="permissionUpdated(perm, $event, node)"
                v-model="perm.selected"
                :disabled="disablePermissions(perm)"
                class="pa-0 ma-0"
                hide-details
              ></v-checkbox>
            </v-list-item-action>
            <v-list-item-content class="pa-0">
              <v-list-item-title class>
                <span class="font-weight-regular">{{
                  perm.display_name ? perm.display_name : perm.name
                }}</span>
              </v-list-item-title>
              <v-list-item-subtitle>
                <span class="font-weight-thin">{{
                  perm.description ? perm.description : ""
                }}</span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </span>
        <v-btn
          v-if="node.is_customizable && showAddPermissionButton"
          @click="addNewPermission(node)"
          :ripple="false"
          small
          color="darkGrey"
          class="font-italic"
          text
        >
          <v-icon left class="font-italic font-weight-light">add</v-icon>Add new
          permission
        </v-btn>
        <br />
      </div>
      <div v-else>
        <p class="subtitle-2 ml-4 font-italic">Resource has no permissions</p>
        <v-btn
          v-if="node.is_customizable && showAddPermissionButton"
          @click="addNewPermission(node)"
          :ripple="false"
          small
          color="darkGrey"
          class="font-italic"
          text
        >
          <v-icon left class="font-italic font-weight-light">add</v-icon>Add new
          permission
        </v-btn>
      </div>
      <div class="ml-7" v-if="node.children.length > 0">
        <node
          :displayOnly="displayOnly"
          :showAddPermissionButton="showAddPermissionButton"
          @permission-selected="
            ({ perm, value, node }) => permissionUpdated(perm, value, node)
          "
          @permission-removed="
            ({ perm, value, node }) => permissionUpdated(perm, value, node)
          "
          @resource-selected="
            ({ resource, value }) => resourceUpdated(resource, value)
          "
          @resource-removed="
            ({ resource, value }) => resourceUpdated(resource, value)
          "
          v-for="child in node.children"
          :node="child"
          :key="child.id"
        ></node>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'node',
  props: {
    node: {},
    showAddPermissionButton: {
      default: false
    },
    displayOnly: {
      default: false
    }
  },
  data() {
    return {
      expanded: false
      // indeterminate: false
    }
  },
  methods: {
    toggleRecursive(node, val) {
      if (node.children.length > 0) {
        for (const childNode of node.children) {
          this.toggleRecursive(childNode, val)
        }
      }
      if (node.permissions && node.permissions.length > 0) {
        for (const perm of node.permissions) {
          perm.selected = val
        }
      }
    },
    checkAllChildNodePermissions(node) {
      // if (node.permissions.length < 1) return false // temp added for users filter tree view
      let allSelected = node.permissions.every(i => i.selected === true)
      if (node.children.length > 0) {
        let allChildPermissionsSelected = allSelected && node.children.every(i => this.checkAllChildNodePermissions(i))
        return allChildPermissionsSelected
      }
      return allSelected
    },
    checkSomeChildNodePermissions(node) {
      return node.permissions.some(i => i.selected === true) || node.children.some(i => this.checkSomeChildNodePermissions(i))
    },
    permissionUpdated(perm, value, node) {
      if (value) {
        this.$emit('permission-selected', { perm, value, node })
      } else {
        this.$emit('permission-removed', { perm, value, node })
      }
    },
    resourceUpdated(resource, value) {
      if (value) {
        this.$emit('resource-selected', { resource, value })
      } else {
        this.$emit('resource-removed', { resource, value })
      }
    },
    disablePermissions(permission) {
      if (this.node.role_permission_limit === 0) {
        // unlimited perms!
        return false
      }
      let selectedPermissions = this.node.permissions.filter(i => i.selected === true)
      return selectedPermissions.length >= this.node.role_permission_limit && permission.selected !== true
    },
    addNewPermission(node) {
      this.$emit('add-new-permission', { node })
    }
  },
  computed: {
    checkboxState: {
      // getter
      get: function () {
        return this.checkAllChildNodePermissions(this.node)
      },
      // setter
      set: function (newValue) {
        this.toggleRecursive(this.node, newValue)
      }
    }
  },
  beforeDestroy() {
    this.expanded = false
  }
}
</script>

<style lang="scss" scoped>
</style>
